/* Buttons */
.btn,
.btn-sm {
  @apply inline-flex items-center justify-center whitespace-nowrap rounded-lg text-sm font-medium transition-all;
}

.btn {
  @apply px-4 py-[11px];
}

.btn-sm {
  @apply px-3 py-[7px];
}

/* Forms */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
  @apply border border-gray-700 bg-gray-900/50 focus:border-gray-600 focus:ring-0 focus:ring-offset-0;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select {
  @apply rounded-lg px-4 py-2.5 text-sm text-gray-200;
}

.form-input,
.form-textarea {
  @apply placeholder-gray-600;
}

.form-select {
  @apply pr-10;
}

.form-checkbox,
.form-radio {
  @apply text-moss-light;
}

.form-checkbox {
  @apply rounded;
}

.masked {
  -webkit-mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1)
  );
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

.fade-in {
  animation: fade-in 1s ease-in-out 1;
}

.bg-slide-static {
  position: relative;
  background: #282923 url('../../public/images/slide-04.jpg') center center
    no-repeat;
  background-size: cover;
}

.bg-slide {
  position: relative;
  background: #282923 url('../../public/images/slide-01.jpg') center center
    no-repeat;
  background-size: cover;
  animation: bg-slide-0 12s ease-in-out infinite;
}

.bg-slide::before,
.bg-slide::after {
  content: '';
  background: #282923 url('../../public/images/slide-01.jpg') center center
    no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  z-index: 0;
}

.bg-slide::before {
  background-image: url('../../public/images/slide-02.jpg');
  animation: bg-slide-1 20s ease-in-out infinite;
}

.bg-slide::after {
  background-image: url('../../public/images/slide-03.jpg');
  animation: bg-slide-2 20s ease-in-out infinite;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes bg-slide-0 {
  0% {
    background-size: 100%;
  }

  50% {
    background-size: 105%;
  }

  100% {
    background-size: 100%;
  }
}

@keyframes bg-slide-1 {
  0% {
    opacity: 0;
    background-size: 100%;
  }

  25% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    background-size: 110%;
  }
}

@keyframes bg-slide-2 {
  0% {
    opacity: 0;
    background-size: 100%;
  }

  55% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    background-size: 110%;
  }
}
