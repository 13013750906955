.box-container,
.box-container *,
.box-container *:before,
.box-container *:after {
  box-sizing: inherit;
}

.box-container {
  @apply sticky top-0;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
}

@media screen and (min-width: 1380px) {
  .box-container {
    flex-direction: row;
  }
}

.box-item {
  @apply max-w-md;
  position: relative;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
}

.flip-card {
  -ms-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.flip-card .background {
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 1rem #000000;
}

.flip-card .backdrop {
  @apply absolute top-0 left-0 right-0 bottom-0 rounded-xl bg-beige/80 m-auto backdrop-blur-sm;
  height: calc(100% - 2rem);
  width: calc(100% - 2rem);
}

@media screen and (max-width: 360px) {
  .flip-card .backdrop {
    height: 100%;
    width: 100%;
  }
}

.flip-card-front,
.flip-card-back {
  @apply rounded-xl;
  position: relative;
  -ms-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -webkit-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  -ms-transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.flip-card.flipped .flip-card-front {
  -ms-transform: rotateY(-180deg);
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.flip-card-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  -ms-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.flip-card.flipped .flip-card-back {
  -ms-transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.flip-card .inner {
  @apply relative w-full h-full rounded-xl text-center;
  outline: 1px solid transparent;
  -webkit-perspective: inherit;
  perspective: inherit;
  z-index: 2;

  -webkit-transform: translateZ(60px) scale(0.94);
  -ms-transform: translateZ(60px) scale(0.94);
  transform: translateZ(60px) scale(0.94);
}

.qrcode {
  @apply w-full h-full max-h-max max-w-max inline-block mx-auto rounded-2xl text-black bg-beige border-beige shadow-md cursor-pointer overflow-hidden duration-300;
}

.coluko-spinner {
  @apply flex justify-center items-center w-20 h-20 absolute top-0 right-0 bottom-0 left-0 m-auto scale-150;
}

.coluko-spinner div {
  @apply absolute w-16 h-16 rounded-full;
}

.coluko-spinner div.spinner-ring {
  @apply border-8 border-solid border-transparent;
  -webkit-animation: 2s coluko infinite alternate;
  animation: 2s coluko infinite alternate;
}

.coluko-spinner div.spinner-ring:nth-child(1) {
  @apply border-x-zinc-200;
}

.coluko-spinner div.spinner-ring:nth-child(2) {
  @apply border-y-zinc-200;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.coluko-spinner div.spinner-dot {
  @apply w-4 h-4 bg-zinc-200;
}

@-webkit-keyframes coluko {
  to {
    transform: rotate(360deg) scale(0.5);
  }
}

@keyframes coluko {
  to {
    transform: rotate(360deg) scale(0.5);
  }
}
