@import 'tailwindcss/base';
@import 'tailwindcss/components';

/* Additional styles */
@import 'additional-styles/utility-patterns.css';
@import 'additional-styles/theme.css';
@import 'additional-styles/flip-card.css';
@import 'tailwindcss/utilities';

.inner-shadow {
  box-shadow: inset 0 0 1rem #000000;
}

.animated-text {
  transition: opacity 0.5s ease-in-out;
}

.max-w-content {
  @apply mx-auto;
  width: 100%;
  max-width: 1280px;
}

.max-w-wide {
  @apply mx-auto;
  width: calc(100% - 3rem);
  max-width: 1280px;
}

@media screen and (max-width: 767px) {
  .max-w-content {
    width: calc(100% - 3rem);
  }

  .max-w-wide {
    width: 100%;
  }
}

.text-tooltip {
  font-size: 0.688rem;
  line-height: 1.5384;
}

table a {
  @apply underline decoration-zinc-200 decoration-2 underline-offset-4 hover:text-zinc-950 hover:decoration-zinc-300;
}
